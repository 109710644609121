import "../css/nav.css";
import M from "materialize-css";
import React, { useEffect } from "react";
import { GoogleMap, useLoadScript, Marker } from '@react-google-maps/api';
const libraries = ['places'];
const mapContainerStyle = {
    width: '100vw',
    height: '100vh',
};
const center = {
    lat: 7.2905715, // default latitude
    lng: 80.6337262, // default longitude
};

const Contact = () => {
    const { isLoaded, loadError } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAtPRYdXqKeQvSmPeBcXmQEToerQ6GPpxo',
        libraries,
    });

    if (loadError) {
        return <div>Error loading maps</div>;
    }

    if (!isLoaded) {
        return <div>Loading maps</div>;
    }

    return (

        <div className="container white-text black">
            <div className="section">
                <div className="row">
                    <div className="col s12 center">
                        <h3><i className="mdi-content-send brown-text"></i></h3>
                        <h4>Contacto</h4>
                    </div>
                </div>
                <div className="row">
                    <div className="col s12 m6">

                        <div className="row">
                            <div className="col s12 m4">
                                <div className="icon-block">
                                    <h5 className="center">Teléfono</h5>
                                    <p className="center">
                                        <a href="tel:️0034911545123" className="center white-text"> ️+34 911 545 123</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col s12 m4">
                                <div className="icon-block">
                                    <h5 className="center">Email</h5>
                                    <p className="center">
                                        <a href="mailto:mariuslebarbier@gmail.com" className="center white-text"> ️mariuslebarbier@gmail.com</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col s12 m4">
                                <div className="icon-block">
                                    <h5 className="center">Dirección</h5>
                                    <p className="center">
                                        Pl. de la Concordia, 4, 28100 Alcobendas, Madrid
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col s12 m6">

                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3031.7986043292613!2d-3.638565873567513!3d40.5460383977586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd422dd277d28725%3A0xbc35cc328d0a13a!2sMarius%20Le%20Barbier!5e0!3m2!1ses!2ses!4v1703954659012!5m2!1ses!2ses" width="100%" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

                    </div>
                </div>

            </div>
        </div >
    );
};

export default Contact;
