import "../css/nav.css";
import M from "materialize-css";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Nav = () => {

    const navigate = useNavigate();

    useEffect(() => {
        // Initialize the side navigation when the component mounts
        const sideNavElement = document.querySelector(".sidenav");
        M.Sidenav.init(sideNavElement, {});
    }, []);
    const updateView = (view) => {
        var sideNavElement = document.querySelector(".sidenav");
        var instance = M.Sidenav.getInstance(sideNavElement);
        instance.close();
        navigate(view);
    }
    return (
        <nav className="black" role="navigation">
            <div className="nav-wrapper container">
                <a id="logo-container" href="/" className="brand-logo">
                    <img className="logo" src="/logo2.png" alt="logo" />
                </a>
                <ul className="right hide-on-med-and-down">
                    <li>
                        <a href="/" onClick={e => { e.preventDefault(); updateView("/") }}>Home</a>
                    </li>
                    <li>
                        <a href="barberia" onClick={e => { e.preventDefault(); updateView("barberia") }}>Barbería</a>
                    </li>
                    <li>
                        <a href="contact" onClick={e => { e.preventDefault(); updateView("contact") }}>Contacto</a>
                    </li>
                    <li>
                        <a href="tel:️0034911545123" className="waves-effect waves-light btn red" >
                            ️+34 911 545 123
                        </a>
                    </li>
                </ul>

                <ul id="nav-mobile" className="sidenav black">
                    <li>
                        <a className="white-text" href="/" onClick={e => { e.preventDefault(); updateView("/") }}>Home</a>
                    </li>
                    <li>
                        <a className="white-text" href="barberia" onClick={e => { e.preventDefault(); updateView("barberia") }}>Barbería</a>
                    </li>
                    <li>
                        <a className="white-text" href="contact" onClick={e => { e.preventDefault(); updateView("contact") }}>Contacto</a>
                    </li>
                    <li>
                        <a href="tel:️0034911545123" className="waves-effect waves-light btn red" >
                            ️+34 911 545 123
                        </a>
                    </li>
                </ul>
                <a
                    href="/"
                    data-target="nav-mobile"
                    className="sidenav-trigger"
                >
                    <i className="material-icons">menu</i>
                </a>
            </div>
        </nav>
    );
};

export default Nav;
