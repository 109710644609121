import "../css/nav.css";
import React, { useEffect } from 'react';
import M from "materialize-css";
import carrouselOne from "../img/carousel/1.png"
import carrouselTwo from "../img/carousel/2.png"
import carrouselThree from "../img/carousel/3.png"
import carrouselFour from "../img/carousel/4.png"
import carrouselFive from "../img/carousel/5.png"
const Home = () => {

    useEffect(() => {
        // var elems = document.querySelectorAll('.carousel');
        // var instances = M.Carousel.init(elems, {
        //     fullWidth: true,
        //     duration: 500
        // });
        var elems = document.querySelectorAll('.slider');
        var instances = M.Slider.init(elems, {
            indicators: false
        });
        setInterval(() => {
            instances.forEach(x => x.next());
        }, 5000);
    }, []);
    return (
        <>
            {/* <div id="index-banner" className="parallax-container">
                <div className="parallax">
                    <img src="/img/parallax1.jpg" alt="Unsplashed background img 1" style={{ transform: "translate3d(-50%, 350.9px, 0px)", opacity: 1 }} />
                </div>

            </div> */}

            {/* <div className="carousel carousel-slider" >
                <a className="carousel-item" href="#one!"><img src={carrouselOne} alt="carrouselOne" /></a>
                <a className="carousel-item" href="#one!"><img src={carrouselTwo} alt="carrouselTwo" /></a>
                <a className="carousel-item" href="#one!"><img src={carrouselThree} alt="carrouselThree" /></a>
                <a className="carousel-item" href="#one!"><img src={carrouselFour} alt="carrouselFour" /></a>
                <a className="carousel-item" href="#one!"><img src={carrouselFive} alt="carrouselFive" /></a>
            </div> */}
            <div className="container-fluid white-text black" style={{ position: "relative", height: "95vh" }}>
                <div className="section"></div>
                <div class="slider fullscreen" >
                    <ul class="slides">
                        <li>
                            <img src={carrouselOne} alt="carrouselOne" />
                            <div class="caption center-align">
                                <h3>Ritual de Estilo</h3>
                                <h5 class="light grey-text text-lighten-3">Corte a corte, excelencia</h5>
                            </div>
                        </li>
                        <li>
                            <img src={carrouselTwo} alt="carrouselTwo" />
                            <div class="caption left-align">
                                <h3>Cortes élite</h3>
                                <h5 class="light grey-text text-lighten-3">Servicio de Primera</h5>
                            </div>
                        </li>
                        <li>
                            <img src={carrouselThree} alt="carrouselThree" />
                        </li>
                        <li>
                            <img src={carrouselFour} alt="carrouselFour" />
                        </li>
                        <li>
                            <img src={carrouselFive} alt="carrouselFive" />
                        </li>
                    </ul>
                </div>
            </div>

            <div className="container white-text black">
                <div className="section">
                    <div className="row">
                        <div className="col s12 m6">
                            <div className="icon-block">
                                <h2 className="center white-text"><i className="material-icons">flash_on</i></h2>
                                <h5 className="center">Calidad y rapidez</h5>
                                <p className="light">¡Bienvenidos a nuestra peluquería, donde la calidad y la rapidez se fusionan para resaltar tu estilo! Nuestro talentoso equipo de estilistas se esfuerza por brindarte cortes excepcionales con eficiencia, asegurando una experiencia estilizada en cada visita. ¡Descubre el equilibrio perfecto entre estilo y servicio de alta calidad con nosotros!</p>
                            </div>
                        </div>

                        <div className="col s12 m6">
                            <div className="icon-block">
                                <h2 className="center white-text"><i className="material-icons">content_cut</i></h2>
                                <h5 className="center">Experiencia</h5>

                                <p className="light">Adéntrate en nuestra peluquería, donde la experiencia se traduce en cortes magistrales. Nuestro equipo de estilistas, con años de destreza acumulada, transformará tu estilo con maestría y creatividad. Descubre el arte del corte con nosotros, donde cada tijeretazo cuenta una historia de experiencia y profesionalismo.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            {/* <div id="index-banner" className="parallax-container">
                <div className="parallax">
                    <img src="/img/parallax1.jpg" alt="Unsplashed background img 1" style={{ transform: "translate3d(-50%, 350.9px, 0px)", opacity: 1 }} />
                </div>
            </div> */}
            <div className="container white-text black">
                <div className="section">
                    <div className="row">
                        <div className="col s12 center">
                            <h3><i className="mdi-content-send brown-text"></i></h3>
                            <h4>Contacto</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col s12 m4">
                            <div className="icon-block">
                                <h5 className="center">Teléfono</h5>
                                <p className="center">
                                    <a href="tel:️0034911545123" className="center white-text"> ️+34 911 545 123</a>
                                </p>
                            </div>
                        </div>

                        <div className="col s12 m4">
                            <div className="icon-block">
                                <h5 className="center">Email</h5>
                                <p className="center">
                                    <a href="mailto:mariuslebarbier@gmail.com" className="center white-text"> ️mariuslebarbier@gmail.com</a>
                                </p>
                            </div>
                        </div>
                        <div className="col s12 m4">
                            <div className="icon-block">
                                <h5 className="center">Dirección</h5>
                                <p className="center">
                                    Pl. de la Concordia, 4, 28100 Alcobendas, Madrid
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
};

export default Home;
