import "./App.css";
import Contact from "./components/contact";
import Gallery from "./components/gallery";
import Home from "./components/home";
import Nav from "./components/nav";
import Barberia from "./components/barberia";
import { Route, Routes } from "react-router-dom";
function App() {
    return (
        <>
            <Nav></Nav>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/barberia" element={<Barberia />} />
                <Route path="/gallery" element={<Gallery />} />
                <Route path="/contact" element={<Contact />} />
            </Routes>
        </>
    );
}

export default App;
