import "../css/nav.css";
import M from "materialize-css";
import React, { useEffect } from "react";

const Gallery = () => {
    return (
        <h1>gallery</h1>
    );
};

export default Gallery;
